
import RegistrationForm from '@/components/RegistrationForm.vue';
import TrackerCallout from '@/components/TrackerCallout.vue';
import SetupLocation from '@/pages/Registration/SetupLocation.vue';
import SetupProfile from '@/pages/Registration/SetupProfile.vue';
import RouteNames from '@/router/names';
import { trackSignUpPageView } from '@/tracking';
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import allowedOrigins from '../../helper-pages/allowed-origins';
import ShowApps from './ShowApps.vue';

export default Vue.extend({
    name: 'AccountRegister',
    metaInfo: {
        title: 'Sign Up',
    },
    components: {
        RegistrationForm,
        SetupLocation,
        SetupProfile,
        ShowApps,
        TrackerCallout,
    },
    data() {
        return {};
    },
    computed: {
        currentUser(): null | CurrentUser {
            return this.$store.state.account.currentUser;
        },
        showAppsNext() {
            return this.$route.query.show === 'apps';
        },
        nextRoute() {
            let redirectUrl = this.$route.query.redirect as string | undefined;
            if (redirectUrl?.includes('://')) {
                if (
                    this.showAppsNext
                    || !allowedOrigins.some(o => redirectUrl?.startsWith(o))
                ) {
                    redirectUrl = undefined;
                }
            }
            return redirectUrl ?? this.$router.resolve({ name: 'actions', query: { welcome: 'welcome' } }).href;
        },
    },
    mounted() {
        trackSignUpPageView();
    },
    methods: {
        async focusNextStep() {
            await this.$nextTick();
            setTimeout(() => {
                if (this.$refs.nextStepHolder instanceof HTMLElement) {
                    const firstInput = this.$refs.nextStepHolder.querySelector('button, input:not([type="file"]), textarea');
                    if (firstInput instanceof HTMLElement) {
                        firstInput.focus();
                    }
                }
            }, 50);
        },
    },
});
